import React,{ } from 'react';
import { NavLink } from 'react-router-dom';

function Services() {
  return (
    <>
        <div>
          <div className="bg-light campus-area grey-2 fix pt-120 pb-70 ">
            <div className="container">
              <div className="campus-wrapper position-relative">
                <div className="row align-items-center py-5">
                  <div className="col-xl-5 col-lg-6">
                    <div className="hero-text">
                      <h5>Our Services</h5>
                      <h2>Data Aggregation and Transformation</h2>
                      <p>We enable our Community of Users to deal with large, raw data without spending long hours in undertaking manual configuration. </p>
                      <p>Dealing with disparate data from various sources in a combination of varying formats can be significantly expensive both in labor and cost. Our data transformation solution configures data into the right format automatically.</p>
                      <div className="hero-btn">
                        <NavLink className="edu-btn" to="https://datag.co.ke/staging/Registration/Register">Get Started</NavLink>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 offset-xl-1 col-lg-6">
                    <div className="campus-img-wrapper position-relative">
                        <div className="campus-img-11">
                          <img src="/img/banner-11.png" alt="image not found" />
                        </div>
                        <div className="campus-img-12">
                          <img src="/img/banner-12.png" alt="image not found" />
                        </div>
                        <NavLink to="/" data-bs-toggle="tooltip" data-bs-placement="top" title="Graduates" className="campus-img-13">
                          <img src="/img/banner-13.png" alt="image not found" />
                        </NavLink>
                        <div className="campus-img-14">
                          <img src="/img/banner-14.png" alt="image not found" />
                        </div>
                        <div className="campus-img-15">
                          <img src="/img/banner-15.png" alt="image not found" />
                        </div>
                        <div className="campus-img-16">
                          <img src="/img/banner-16.png" alt="image not found" />
                        </div>
                        <div className="campus-img-17">
                          <img src="/img/banner-17.png" alt="image not found" />
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="about-area p-relative pt-90 pb-70">
            <div className="container">
              {/* <img className="about-shape" src="assets/img/shape/education-shape-03.png" alt="shape" /> */}
              <div className="row">
                <div className="col-xl-6 col-lg-6">
                  <div className="about-img position-relative mb-50">
                    <div className="about-main-img">
                      <img src="img/data-aggrigation.gif" alt="about" />
                    </div>
                    {/* <img className="about-shape-1" src="/img/shape/education-shape-01.png" alt="about" />
                    <img className="about-shape-2" src="assets/img/shape/education-shape-02.png" alt="about" />
                    <img className="about-shape-3" src="assets/img/shape/education-shape-05.png" alt="about" /> */}
                  </div>
                </div>
                <div className="col-xl-5 col-lg-5">
                  <div className="about-content mb-50">
                    <div className="section-title mb-30">
                      <h2>We have developed capacity to transform unstructured data into actionable 	structured datasets.
                      </h2>
                    </div>
                    <div className="student-choose-list">
                      <p className=" mb-1">Aggregation is a vital process in data analysis. Our data aggregation system ensures accurate, high-quality data collection, presented in a summarized format and ready for the data analysts and the ultimate data consumer.</p>
                      <p>The outcome of aggregated data can be beneficial to all organizations — from decision-making to business strategists, product developers and even finance.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="become-intructor-area pt-110 pb-120">
            <div className="container">
                <div className="row justify-content-center text-center">
                  <div className="col-xl-6 col-md-8">
                      <div className="become-intructor-text">
                      <h2>Get started with DATA G</h2>
                      <p>Our platform provides information that helps users to access information they may require as part of the near complete information to enable them remove significant uncertainties in decision making processes when taking part in various transactions.</p>
                      <a className="edu-btn" href="https://datag.co.ke/staging/Registration/Register">Get started now</a>
                      </div>
                  </div>
                  </div>
              </div>
          </div>
      </div>
    </>
  );
}

export default Services;