import React, { useState, useEffect } from 'react';

import { Link, NavLink } from "react-router-dom";

function HomeGallery() {
    const [switchImageTwo, setSwitchImageTwo] = useState(false);
    const [switchImageFour, setSwitchImageFour] = useState(false);
    const [switchImageFive, setSwitchImageFive] = useState(false);

    const campusImages = {
        "campus-img-0": [
          { src: "/img/banner-0.png", alt: "Property",link:"/property" },
          // Add more items if needed
        ],
        "campus-img-1": [
          { src: "/img/banner-1.png", alt: "Motor Vehicle",link:"/vehicles" },
          // Add more items if needed
        ],
        "campus-img-2": [
          { src: "/img/banner-2.png", alt: "Land Publications",link:"/land" },
          { src: "/img/banner-2.2.png", alt: "Individual Publications",link:"/individual" },
          // Add more items if needed
        ],
        "campus-img-3": [
          { src: "/img/banner-3.png", alt: "Individual Publications - Graduates",link:"/individual" },
          // Add more items if needed
        ],
        "campus-img-4": [
          { src: "/img/banner-4.png", alt: "Individual Publications - Graduates",link:"/individual" },
          { src: "/img/banner-4.4.png", alt: "Vehicle Publications",link:"/vehicles" },
          // Add more items if needed
        ],
        "campus-img-5": [
          { src: "/img/banner-5.png", alt: "Individual Publications",link:"/individual" },
          { src: "/img/banner-5.5.png", alt: "Land Publication",link:"/land" },
          // Add more items if needed
        ],
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setSwitchImageTwo((prevValue) => !prevValue);
            setSwitchImageFour((prevValue) => !prevValue);
            setSwitchImageFive((prevValue) => !prevValue);
        }, 5000);

        // Clear the timer when component unmounts or when the timer changes
        return () => clearTimeout(timer);
    }, [switchImageTwo,switchImageFour,switchImageFive]);

    return (
        <>
             <div className="campus-img-wrapper position-relative">
                {/* Render individual NavLink components for each campus image */}
                <NavLink
                    to={campusImages["campus-img-0"][0].link}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={campusImages["campus-img-0"][0].alt}
                    className="campus-img-0"
                >
                    <img src={campusImages["campus-img-0"][0].src} alt={campusImages["campus-img-0"][0].alt} />
                </NavLink>
                <NavLink
                    to={campusImages["campus-img-1"][0].link}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={campusImages["campus-img-1"][0].alt}
                    className="campus-img-1"
                >
                    <img src={campusImages["campus-img-1"][0].src} alt={campusImages["campus-img-1"][0].alt} />
                </NavLink>
                <NavLink
                    to={switchImageTwo ? campusImages["campus-img-2"][1].link : campusImages["campus-img-2"][0].link}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={switchImageTwo ? campusImages["campus-img-2"][1].alt : campusImages["campus-img-2"][0].alt}
                    className="campus-img-2"
                >
                    <img src={switchImageTwo ? campusImages["campus-img-2"][1].src : campusImages["campus-img-2"][0].src} alt={switchImageTwo ? campusImages["campus-img-2"][1].alt : campusImages["campus-img-2"][0].alt} />
                </NavLink>
                <NavLink
                    to={campusImages["campus-img-3"][0].link}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={campusImages["campus-img-3"][0].alt}
                    className="campus-img-3"
                >
                    <img src={campusImages["campus-img-3"][0].src} alt={campusImages["campus-img-3"][0].alt} />
                </NavLink>
                <NavLink
                    to={switchImageFour ? campusImages["campus-img-4"][1].link : campusImages["campus-img-4"][0].link}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={switchImageFour ? campusImages["campus-img-4"][1].alt : campusImages["campus-img-4"][0].alt}
                    className="campus-img-4"
                >
                    <img src={switchImageFour ? campusImages["campus-img-4"][1].src : campusImages["campus-img-4"][0].src} alt={switchImageFour ? campusImages["campus-img-4"][1].alt : campusImages["campus-img-4"][0].alt} />
                </NavLink>
                <NavLink
                    to={switchImageFive ? campusImages["campus-img-5"][1].link : campusImages["campus-img-5"][0].link}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={switchImageFive ? campusImages["campus-img-5"][1].alt : campusImages["campus-img-5"][0].alt}
                    className="campus-img-5"
                >
                    <img src={switchImageFive ? campusImages["campus-img-5"][1].src : campusImages["campus-img-5"][0].src} alt={switchImageFive ? campusImages["campus-img-5"][1].alt : campusImages["campus-img-5"][0].alt} />
                </NavLink>
            </div>
        </>
    );
}

export default HomeGallery;