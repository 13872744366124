import React,{ } from 'react';
import { NavLink } from 'react-router-dom';

function Vehicles() {
  return (
    <>
        <div>
          <div className="bg-light campus-area grey-2 fix pt-120 pb-70 ">
            <div className="container">
              <div className="campus-wrapper position-relative">
                {/* <div className="campus-shape-sticker">
                    <div className="shape-light">
                        <img src="~/wwwroot/theme/img/shape/shape-light.png" alt="image not found">
                    </div>
                    <div className="campus-shape-content">
                        <h5>Education is the<span> Platform
                                that makes</span>possible to everyone</h5>
                    </div>
                </div> */}
                <div className="row align-items-center py-5">
                  <div className="col-xl-5 col-lg-6">
                    <div className="hero-text">
                      <h5>Due Diligence Datasets</h5>
                      <h2>Land Publications </h2>
                      <p>Land-is regarded as the greatest among the natural resources and is one of the most important components of life support systems. Land sustains many of the living beings; agricultural activities including animal husbandry.  It supports forests and grasslands; human settlement and wildlife depend on land.</p>
                      <p>Furthermore, industrialization and urbanization depend on land and so do construction of roads, airports and railway lines. In a Nutshell-land is the foundation of our economy and society</p>
                      <div className="hero-btn">
                        <NavLink className="edu-btn" to="https://datag.co.ke/staging/Registration/Register">Get Started</NavLink>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 offset-xl-1 col-lg-6">
                  <div className="campus-img-wrapper position-relative">
                      <div className="campus-img-21">
                          <img src="/img/banner-21.png" alt="image not found" />
                      </div>
                      <div className="campus-img-22">
                          <img src="/img/banner-22.png" alt="image not found" />
                      </div>
                      <NavLink to="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Land Publications" className="campus-img-23">
                          <img src="/img/banner-23.png" alt="image not found" />
                      </NavLink>
                      <div className="campus-img-24">
                          <img src="/img/banner-24.png" alt="image not found" />
                      </div>
                      <NavLink to="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Land Publications" className="campus-img-25">
                          <img src="/img/campus/campus-img-16.png" alt="image not found" />
                      </NavLink>
                      <div className="campus-img-26">
                          <img src="/img/banner-26.png" alt="image not found" />
                      </div>
                      <div className="campus-img-27">
                          <img src="/img/banner-27.png" alt="image not found" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="about-area p-relative pt-90 pb-70">
            <div className="container">
              {/* <img className="about-shape" src="assets/img/shape/education-shape-03.png" alt="shape" /> */}
              <div className="row">
                <div className="col-xl-6 col-lg-6">
                  <div className="about-img position-relative mb-50">
                    <div className="about-main-img">
                      <img src="img/data-aggrigation.gif" alt="about" />
                    </div>
                    {/* <img className="about-shape-1" src="/img/shape/education-shape-01.png" alt="about" />
                    <img className="about-shape-2" src="assets/img/shape/education-shape-02.png" alt="about" />
                    <img className="about-shape-3" src="assets/img/shape/education-shape-05.png" alt="about" /> */}
                  </div>
                </div>
                <div className="col-xl-5 col-lg-5">
                  <div className="about-content mb-50">
                    <div className="student-choose-list">
                        <p><b>Use and occupation of and title to land in Kenya is a heavily regulated affair with constitutional, legislative and elaborate regulatory provisions.</b></p>
                      <p className=" mb-1">Typically, therefore, land transactions involve significant and at times very large sums of money; there are deeply engrained vested interests and land transaction disputes can be highly emotive, involve multiple actors, transcend numerous generations and can prove very costly. More often than not, a wrong decision on a land transaction may prove calamitous resulting in an existential threat to an individual, a family, a community or an organization. </p>
<p>Transactions and dealings in land are characterized by statutory procedural prescriptions resulting in numerous publications and notices in the Kenya Gazette and Daily Newspapers.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="become-intructor-area pt-110 pb-120">
            <div className="container">
                <div className="row justify-content-center text-center">
                  <div className="col-12">
                      <div className="become-intructor-text">
                      <h2>What You Get</h2>
                      <p>The list of individual publication data sets include the following</p>
                      <div className='row'>
                        <div className='col-md-6'>
                            <div className="student-choose-list">
                                <ul>
                                <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Issue of a Provisional Certificate</li>
                                <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Issue of a Certificate of Lease</li>
                                <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Issue of a New Land Title Deed</li>
                                <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Reconstruction of Lost or Destroyed Land Register</li>
                                <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Loss of Land Register</li>
                                <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Notification of Conversion</li>
                                <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Opening of a New Register</li>
                                <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Registration of Instrument</li>
                                <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Closure of private roads and footpath</li>
                                <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Rectification and cancellation of registration</li>
    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Reservation of Land</li>                                </ul>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="student-choose-list">
                                <ul>
                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Deletion Corrigendum and Addendum</li>
                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Notice of Inquiry</li>
                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Intention to acquire</li>
                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Reconstruction of a Green Card</li>
                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Recalling of a Certificate of Title</li>
                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Loss of a Green Card</li>
                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Change of Use(r)</li>
                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Renewal of Lease</li>
                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Notification of Sale by Public Auction</li>
                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Caveat and/or Caution</li>
                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Notification of Court proceedings/court order</li>
                                </ul>
                            </div>
                        </div>
                      </div>
                      </div>
                  </div>
                  </div>
              </div>
          </div>
      </div>
    </>
  );
}

export default Vehicles;