import React, { useState, useEffect } from 'react';

import { Link, NavLink,useLocation  } from "react-router-dom";
import Menu from './Sections/Menu';

function Header() {
    const [isLoading, setIsLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const isHidden = true;
    const location = useLocation();

    const toggleSideInfo = () => {
        setIsOpen(!isOpen);
    };

    const closeSideInfo = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        // Simulating loading delay
        const timeout = setTimeout(() => {
        setIsLoading(false);
        }, 2000);

        return () => clearTimeout(timeout);
    }, [location.pathname]);

  return (
    <>
        {isLoading && (
        <div id="loading">
          <div id="loading-center">
            <div id="loading-center-absolute">
              <div className="loading-icon text-center d-flex flex-column align-items-center justify-content-center">
                <img className="loading-logo" src="/img/logo/preloader.svg" alt="LOADING" />
              </div>
            </div>
          </div>
        </div>
      )}

        <div>
            <div className="fix">
                <div className={`side-info ${isOpen ? 'info-open' : ''}`}>
                    <div className="side-info-content">
                        <div className="offset-widget offset-logo mb-40">
                            <div className="row align-items-center">
                                <div className="col-9">
                                    <NavLink to="/">
                                        <img src="/img/dg_logo_black.png" height={60} alt="Logo" />
                                    </NavLink>
                                </div>
                                <div className="col-3 text-end">
                                    <button className="side-info-close" onClick={closeSideInfo}><i className="fas fa-times" /></button>
                                </div>
                            </div>
                        </div>
                        <div className="mobile-menu d-xl-none fix mb-50">
                        <div className="mean-bar">
                            <NavLink className="meanmenu-reveal">
                                <span><span><span></span></span></span>
                            </NavLink>
                            <nav className="mean-nav">
                                <ul>
                                    <li className="menu-item-has-children mb-3">
                                        <NavLink className="fw-bold d-flex align-items-center gap-3">
                                            <span>About Us</span> <i className="bi bi-plus" />
                                        </NavLink>
                                        <ul className='sub-menu'>
                                            <li>
                                                <NavLink to="/" onClick={closeSideInfo}>
                                                    Who We Are
                                                </NavLink>
                                            </li> <li>
                                                <NavLink to="/guide" onClick={closeSideInfo}>
                                                    How It Works
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/" onClick={closeSideInfo}>
                                                    FAQ
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="menu-item-has-children mb-3">
                                        <NavLink to="/solutions" className="fw-bold d-flex align-items-center gap-3">
                                            <span>Solutions</span> <i className="bi bi-plus" />
                                        </NavLink>
                                        <ul className='sub-menu'>
                                            <li>
                                                <NavLink to="/solutions#risk" onClick={closeSideInfo}>
                                                    Risk Intelligence 
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/solutions#media" onClick={closeSideInfo}>
                                                    Media Intelligence 
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/solutions#kyc" onClick={closeSideInfo}>
                                                    Know Your Customer (KYC)
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className='mb-3'>
                                        <NavLink to="/services" className="fw-bold" onClick={closeSideInfo}>
                                            Services
                                        </NavLink>
                                    </li>
                                    <li className="menu-item-has-children mb-3">
                                        <NavLink className="fw-bold d-flex align-items-center gap-3">
                                            <span>Industries</span> <i className="bi bi-plus" />
                                        </NavLink>
                                        <ul className='sub-menu'>
                                            <li>
                                                <NavLink to="/industries#finance" onClick={closeSideInfo}>
                                                    Financial Institutions 
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/industries#micro" onClick={closeSideInfo}>
                                                    Micro Finance Institutions 
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/industries#sme" onClick={closeSideInfo}>
                                                    Micro-, small- and medium-sized enterprises (MSMEs)
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className='mb-3'>
                                        <NavLink to="/" className="fw-bold" onClick={closeSideInfo}>
                                            Careers
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/" className="fw-bold" onClick={closeSideInfo}>
                                            Blog
                                        </NavLink>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        </div>
                        {/* <div className="offset-widget offset_searchbar mb-30">
                            <div className="menu-search position-relative ">
                                <form action="#" className="filter-search-input">
                                    <input type="text" placeholder="Search keyword" />
                                    <button><i className="fal fa-search" /></button>
                                </form>
                            </div>
                        </div> */}
                        <div className="offset-widget offset_menu-top mb-20">
                            <div className="header-menu-top-icon mb-20">
                                <NavLink className="mb-5"><i className="fas fa-phone" />(+254) 799 298 611</NavLink>
                                <NavLink><i className="fas fa-phone" />(+254) 703 176 392</NavLink>
                                <NavLink><i className="fas fa-envelope" />P.O BOX 60038-00200 Nairobi – Kenya</NavLink>
                                <NavLink><i className="fas fa-envelope" />sales@dataghala.com</NavLink>
                                <NavLink><i className="fas fa-envelope" />support@dataghala.com</NavLink>
                                <i className="fas fa-map-marker-alt" /><span>DATA GHALA LTD Kogo Star Plaza, Office No. 16 Ground Floor, Nairobi West</span>
                            </div>
                        </div>
                        <div className="offset-widget button mb-20">
                            <NavLink className="edu-four-btn">Get Started</NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`offcanvas-overlay ${isOpen ? 'overlay-open' : ''}`}/>
            <div className="offcanvas-overlay-white" />
            
            <header>
                <div className="header-area header-transparent sticky-header">
                    <div className="container">
                        <div className="header-main-wrapper">
                            <div className="row align-items-center">
                                <div className="col-xl-8 col-lg-8 col-md-5 col-sm-9 col-9">
                                <div className="header-left d-flex align-items-center justify-content-between">
                                    <div className="header-logo">
                                        <NavLink to="/">
                                            <img src="./img/dg_logo_black.png" height={60} alt={"logo"}></img>
                                        </NavLink>
                                    </div>
                                    <div className="main-menu d-none d-xl-block">
                                    <nav id="mobile-menu">
                                        <ul>
                                            <li className="menu-item-has-children">
                                                <NavLink>
                                                    <span>About Us</span> <i className="bi bi-chevron-down" />
                                                </NavLink>
                                                <ul className='sub-menu'>
                                                    <li>
                                                        <NavLink to="/" >
                                                            Who We Are
                                                        </NavLink>
                                                    </li> <li>
                                                        <NavLink to="/guide" >
                                                            How It Works
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to="/" >
                                                            FAQ
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="menu-item-has-children">
                                                <NavLink to="/solutions">
                                                    <span>Solutions</span> <i className="bi bi-chevron-down" />
                                                </NavLink>
                                                <ul className='sub-menu'>
                                                    <li>
                                                        <NavLink to="/solutions#risk" >
                                                            Risk Intelligence 
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to="/solutions#media" >
                                                            Media Intelligence 
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to="/solutions#kyc" >
                                                            Know Your Customer (KYC)
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="menu-item-has-children">
                                                <NavLink to="#">
                                                    <span>Services</span> <i className="bi bi-chevron-down" />
                                                </NavLink>
                                                <ul className='sub-menu'>
                                                    <li>
                                                        <NavLink to="/services" >
                                                            Data Aggregation
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to="/diligence" >
                                                            Due Diligence
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="menu-item-has-children">
                                                <NavLink to="/industries" >
                                                    <span>Industries</span> <i className="bi bi-chevron-down" />
                                                </NavLink>
                                                <ul className='sub-menu'>
                                                    <li>
                                                        <NavLink to="/industries#finance" >
                                                            Financial Institutions 
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to="/industries#micro" >
                                                            Micro Finance Institutions 
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to="/industries#sme" >
                                                            Micro-, small- and medium-sized enterprises (MSMEs)
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <NavLink to="/" >
                                                    Careers
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/" >
                                                    Blog
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </nav>
                                    </div>
                                </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-7 col-sm-3 col-3">
                                    <div className="header-right d-flex align-items-center justify-content-end">
                                        <div className="user-btn-inner p-relative d-none d-md-block">
                                            <div className="user-btn-wrapper">
                                                <div className="user-btn-content ">
                                                    <NavLink className="user-btn-sign-in" to="http://portal.datag.co.ke/Login/Login">
                                                        Sign In
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-none d-md-block">
                                            <NavLink className="user-btn-sign-up edu-btn" to="http://portal.datag.co.ke/Registration/Register">
                                                Sign Up
                                            </NavLink>
                                        </div>
                                        <div className="menu-bar d-xl-none ml-20">
                                            <NavLink onClick={toggleSideInfo}>
                                                <div className="bar-icon">
                                                    <span />
                                                    <span />
                                                    <span />
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    </>
  );
}

export default Header;