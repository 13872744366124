import React,{useEffect } from 'react';

import HomeHero from '../components/Hero/HomeHero';
import Features from '../components/Sections/Features';
import FeatureVideo from '../components/Sections/FeatureVideo';
import Counter from '../components/Sections/Counter';

import { NavLink } from 'react-router-dom';

function Home() {
  return (
    <div>
        <HomeHero/>
        <Features/>
        <FeatureVideo/>

        <Counter/>

        <div className="know-us-better-area pb-90">
            <div className="container">
                <div className="know-us-border pt-110">
                <div className="row">
                    <div className="col-md-4">
                        <div className="section-title mb-55">
                            <span>Services & Solutions</span>
                            <h2>
                                What We Offer
                            </h2>
                        </div>
                    </div>
                    <div className="col-md-6 justify-content-end">
                        <div className="know-us-tittle mb-55">
                            <p>
                            We enable our Community of Users to deal with large, raw data without spending long hours in undertaking manual configuration. 
                            </p>
                        </div>
                    </div>
                    <div className='col-md-2'>
                        <div className="student-btn">
                            <NavLink className="edu-sec-btn bdevs-el-btn edu-sec-btn bdevs-el-btn" to="/services">
                                <span>Learn More</span>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-6">
                    <div className="know-us-wrapper mb-30">
                        <div className="know-us-better-thumb">
                        <NavLink to="/solutions"><img src="/img/find-out.jpg" alt="know-us-img" /></NavLink>
                        </div>
                        <div className="know-us-text text-center">
                        <NavLink to="/solutions">
                            <h3>Risk Intelligence</h3>
                        </NavLink>
                        </div>
                    </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6">
                    <div className="know-us-wrapper mb-30">
                        <div className="know-us-better-thumb">
                        <NavLink to="/solutions"><img src="/img/target.jpg" alt="know-us-img" /></NavLink>
                        </div>
                        <div className="know-us-text text-center">
                        <NavLink to="/Solutions">
                            <h3>Know Your Customer (KYC)</h3>
                        </NavLink>
                        </div>
                    </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6">
                    <div className="know-us-wrapper mb-30">
                        <div className="know-us-better-thumb">
                        <NavLink to="/Solutions"><img src="/img/conference.jpg" alt="know-us-img" /></NavLink>
                        </div>
                        <div className="know-us-text text-center">
                        <NavLink to="/Solutions">
                            <h3>Media Intelligence</h3>
                        </NavLink>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>

        <div className="become-intructor-area pt-110 pb-120">
            <div className="container">
                <div className="row justify-content-center text-center">
                <div className="col-xl-6 col-md-8">
                    <div className="become-intructor-text">
                    <h2>Get started with DATA G</h2>
                    <p>Our platform provides information that helps users to access information they may require as part of the near complete information to enable them remove significant uncertainties in decision making processes when taking part in various transactions.</p>
                    <a className="edu-btn" href="http://portal.datag.co.ke/Registration/Register">Get started now</a>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Home;