import React,{ } from 'react';
import { NavLink } from 'react-router-dom';

function Guide() {
  return (
    <>
        <div>
            <div className="breadcrumbs bg-light pt-150 pb-50">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2>How It Works</h2>
                        <ol>
                            <li>User Guide</li>
                        </ol>
                    </div>
                </div>
            </div>
            <section id="features" className="features pt-50 pb-50">
                <div className="container">
                    <div className="row gy-4 align-items-center features-item aos-init aos-animate" data-aos="fade-up">
                        <div className="col-md-5">
                            <img src="/media/datag-login.png" className="img-fluid" alt="Not found" />
                        </div>
                        <div className="col-md-7">
                            <h3>Log In or Sign Up</h3>
                            <p>To get started, you will need to log in or sign up for an account.</p>
                            <p>If you are a new user, you will need to create an account by filling out the registration form with your personal details and choosing a secure password.</p>
                            <div className="mt-3 d-flex gap-3">
                                <NavLink className="btn-getstarted scrollto" to="https://datag.co.ke/staging/Registration/Register" >
                                    Sign In
                                </NavLink>
                                <NavLink className="btn-getstarted scrollto" to="https://datag.co.ke/staging/Login/Login" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Login to your account">
                                    Sign In
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <hr className="border-dashed my-5" />
                    <div className="row gy-4 align-items-center features-item aos-init aos-animate" data-aos="fade-up">
                        <div className="col-md-5 order-1 order-md-2">
                            <img src="/media/datag-memberdashboard.png" className="img-fluid" alt="Not found" />
                        </div>
                        <div className="col-md-7 order-2 order-md-1">
                            <h3>Members landing page</h3>
                            <p>
                                Once you are logged in, you will be directed to the members landing page.
                            </p>
                            <p>
                                This is your dashboard where you can manage your account, view your search history, and access your certificates.
                            </p>
                        </div>
                    </div>
                <hr className="border-dashed my-5" />
                <div className="row gy-4 align-items-center features-item aos-init aos-animate" data-aos="fade-up">
                    <div className="col-md-5">
                        <img src="/media/datag-database-list.png" className="img-fluid" alt="Not found" />
                    </div>
                    <div className="col-md-7">
                        <h3>Select a database to search</h3>
                        <p>To search for a record, you will need to select a database from the list of available databases.</p>
                        <p>We offer a wide range of databases covering different industries and geographic locations.</p>
                        <p>You can browse through the database list to find the one that best fits your needs.</p>
                    </div>
                </div>
                <hr className="border-dashed my-5" />
                <div className="row gy-4 align-items-center features-item aos-init aos-animate" data-aos="fade-up">
                    <div className="col-md-5 order-1 order-md-2">
                        <img src="/media/datag-search-data.png" className="img-fluid" alt="Not found" />
                    </div>
                    <div className="col-md-7 order-2 order-md-1">
                        <h3>Search a record</h3>
                        <p>
                            Once you have selected a database, you can start searching for records with specific key words
                        </p>
                    </div>
                </div>
                <hr className="border-dashed my-5" />
                <div className="row gy-4 align-items-center features-item aos-init aos-animate" data-aos="fade-up">
                    <div className="col-md-5">
                        <img src="/media/datag-payment-modal-1.png" className="img-fluid" alt="Not found" />
                    </div>
                    <div className="col-md-7">
                        <h3>Make a payment</h3>
                        <p>When you find the record you are looking for, you can make a payment to access it.</p>
                        <p>Our payment system is secure and easy to use. Simply select the payment option that works best for you, enter your payment details, and confirm your purchase.</p>
                    </div>
                </div>
                <hr className="border-dashed my-5" />
                <div className="row gy-4 align-items-center features-item aos-init aos-animate" data-aos="fade-up">
                    <div className="col-md-5 order-1 order-md-2">
                        <img src="/media/datag-certificate.png" className="img-fluid" alt="Not found" />
                    </div>
                    <div className="col-md-7 order-2 order-md-1">
                    <h3>Download the certificate</h3>
                    <p>
                        After you have made your payment, you can download your certificate.
                    </p>
                    <p>Our certificates are professionally designed and provide detailed information about the record you have purchased.</p>
                    </div>
                </div>
                <hr className="border-dashed my-5" />
                <p className="fst-italic">
                    Overall, our platform is designed to make it easy for you to search for and access the records you need. Whether you are a business owner, researcher, or student, we have the tools and resources to help you succeed.
                </p>
                </div>
            </section>
        </div>
    </>
  );
}

export default Guide;