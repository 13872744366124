import React,{useEffect } from 'react';
import './App.css'; 

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Guide from "./components/Pages/Guide";
import Solutions from "./components/Pages/Solutions";
import Services from "./components/Pages/Services";
import Individual from "./components/Products/Individual";
import Land from "./components/Products/Land";
import Vehicles from "./components/Products/Vehicles";
import Corporate from "./components/Products/Corporate";
import Diligence from "./components/Pages/Diligence";
import Industries from "./components/Pages/Industries";
import { Route, Routes,useLocation } from "react-router-dom";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/guide" element={<Guide />} />
        <Route path="/solutions" element={<Solutions />} />
        <Route path="/services" element={<Services />} />
        <Route path="/individual" element={<Individual />} />
        <Route path="/land" element={<Land />} />
        <Route path="/vehicles" element={<Vehicles />} />
        <Route path="/corporate" element={<Corporate />} />
        <Route path="/diligence" element={<Diligence />} />
        <Route path="/industries" element={<Industries />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
