import React,{ } from 'react';
import { NavLink } from 'react-router-dom';

function Diligence() {
  return (
    <>
        <div>
          <div className="bg-light campus-area grey-2 fix pt-120 pb-70 ">
            <div className="container">
              <div className="campus-wrapper position-relative">
                <div className="row align-items-center py-5">
                  <div className="col-xl-5 col-lg-6">
                    <div className="hero-text">
                      <h5>Our Services</h5>
                      <h2>DATA G Due Diligence Solutions</h2>
                      <p>Right decision- be it for the individual or an organization demands a lot.  Exponential increase in regulation and consumer expectations, has birthed absolute necessity to gather as much information as is available such that every piece of information matters-Due Diligence.</p>
                      <p>Data G serves a wealth of country specific data derived from thousands of content sources, allowing you to conduct comprehensive checks on land, vehicle, people and companies from a single platform.  </p>
                      <div className="hero-btn">
                        <NavLink className="edu-btn" to="https://datag.co.ke/staging/Registration/Register">Get Started</NavLink>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 offset-xl-1 col-lg-6">
                    <div className="campus-img-wrapper position-relative">
                      <NavLink className="campus-img-21" to="/individual">
                          <img src="/img/banner-21.png" alt="image not found" />
                      </NavLink>
                      <div className="campus-img-22">
                          <img src="/img/banner-22.png" alt="image not found" />
                      </div>
                      <NavLink to="/land" data-bs-toggle="tooltip" data-bs-placement="top" title="Land Publications" className="campus-img-23">
                          <img src="/img/banner-23.png" alt="image not found" />
                      </NavLink>
                      <div className="campus-img-24">
                          <img src="/img/banner-24.png" alt="image not found" />
                      </div>
                      <NavLink to="/land" data-bs-toggle="tooltip" data-bs-placement="top" title="Land Publications" className="campus-img-25">
                          <img src="/img/campus/campus-img-16.png" alt="image not found" />
                      </NavLink>
                      <div className="campus-img-26">
                          <img src="/img/banner-26.png" alt="image not found" />
                      </div>
                      <div className="campus-img-27">
                          <img src="/img/banner-27.png" alt="image not found" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="about-area p-relative pt-90 pb-70">
            <div className="container">
              {/* <img className="about-shape" src="assets/img/shape/education-shape-03.png" alt="shape" /> */}
              <div className="row align-items-center">
                <div className="col-xl-6 col-lg-6">
                  <div className="about-img position-relative mb-50">
                    <div className="about-main-img">
                      <img src="img/data-report.gif" alt="about" />
                    </div>
                    {/* <img className="about-shape-1" src="/img/shape/education-shape-01.png" alt="about" />
                    <img className="about-shape-2" src="assets/img/shape/education-shape-02.png" alt="about" />
                    <img className="about-shape-3" src="assets/img/shape/education-shape-05.png" alt="about" /> */}
                  </div>
                </div>
                <div className="col-xl-5 col-lg-5">
                  <div className="about-content mb-50">
                    <div className="section-title mb-30">
                      <h3>Quality and Effectiveness of your due diligence research.</h3>
                    </div>
                    <div className="student-choose-list">
                      <p className=" mb-1">An effective due diligence process should remove significant doubts and inform you of any potential risks before you make a major decision, such as whether to move forward with a transaction to purchase land, to purchase motor vehicle, to approve credit facility, to underwrite a risk, merger and acquisition (M&A) or hire a c-suite candidate for your company. Having access to the right data, in the right context and in the right format, is therefore very crucial for your due diligence process to be efficacious and meaningful.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="become-intructor-area pt-110 pb-120">
            <div className="container">
                <div className="row justify-content-center text-center">
                  <div className="col-xl-8 col-md-8">
                    <div className="become-intructor-text">
                      <h2>Due Diligence Datasets</h2>
                      <p>Data G-by Data Ghala Ltd is a centralized Database and Warehouse that processes and stores select published information relating to various transactions involving individuals, corporations and property from multiple sources. These range from unstructured text-based reports and biographical data to more structured public information or public records. Each dataset is enriched with meta data to allow you to find what you need easier and quicker.</p>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 mb-4'>
                    <NavLink to="/individual">
                      <div className="catagory-wrapper">
                        <div className="catagory-thumb">
                            <svg width="81.549" height="86.553" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.4" d="M17.9981 7.16C17.9381 7.15 17.8681 7.15 17.8081 7.16C16.4281 7.11 15.3281 5.98 15.3281 4.58C15.3281 3.15 16.4781 2 17.9081 2C19.3381 2 20.4881 3.16 20.4881 4.58C20.4781 5.98 19.3781 7.11 17.9981 7.16Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path opacity="0.4" d="M16.9675 14.4402C18.3375 14.6702 19.8475 14.4302 20.9075 13.7202C22.3175 12.7802 22.3175 11.2402 20.9075 10.3002C19.8375 9.59016 18.3075 9.35016 16.9375 9.59016" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path opacity="0.4" d="M5.96656 7.16C6.02656 7.15 6.09656 7.15 6.15656 7.16C7.53656 7.11 8.63656 5.98 8.63656 4.58C8.63656 3.15 7.48656 2 6.05656 2C4.62656 2 3.47656 3.16 3.47656 4.58C3.48656 5.98 4.58656 7.11 5.96656 7.16Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path opacity="0.4" d="M6.9975 14.4402C5.6275 14.6702 4.1175 14.4302 3.0575 13.7202C1.6475 12.7802 1.6475 11.2402 3.0575 10.3002C4.1275 9.59016 5.6575 9.35016 7.0275 9.59016" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M12.0001 14.6302C11.9401 14.6202 11.8701 14.6202 11.8101 14.6302C10.4301 14.5802 9.33008 13.4502 9.33008 12.0502C9.33008 10.6202 10.4801 9.47021 11.9101 9.47021C13.3401 9.47021 14.4901 10.6302 14.4901 12.0502C14.4801 13.4502 13.3801 14.5902 12.0001 14.6302Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M9.0907 17.7804C7.6807 18.7204 7.6807 20.2603 9.0907 21.2003C10.6907 22.2703 13.3107 22.2703 14.9107 21.2003C16.3207 20.2603 16.3207 18.7204 14.9107 17.7804C13.3207 16.7204 10.6907 16.7204 9.0907 17.7804Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </div>
                          <div className="catagory-content">
                            <h3 className="elementor-inline-editing bdevs-el-title" data-elementor-setting-key="title">Individual Persons Publications</h3>
                            <span className="bdevs-el-desc">Persons (both natural and juridical); business and other entities (including statutory bodies), the legal instruments that facilitate relations between persons and entities...</span>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                  <div className='col-md-6 mb-4'>
                    <NavLink to="/land">
                      <div className="catagory-wrapper">
                        <div className="catagory-thumb">
                        <svg width="55.594" height="56.553" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 422.932 422.932" xmlSpace="preserve">
                        <g>
                            <path style={{fill: 'none'}} d="M160.986,108.586c-3.877,6.396-41.223,54.328-41.795,54.953c-2.273,2.482-5.484,3.896-8.85,3.896
                                                c-3.367,0-6.578-1.414-8.85-3.896c-0.572-0.625-37.92-48.557-41.797-54.953H35.92v181.195l181.195-181.195H160.986z" />
                            <polygon style={{fill: 'none'}} points="52.891,398.932 276.276,398.932 164.584,287.24 	" />
                            <path style={{fill: 'none'}} d="M202.432,232.902c-7.434-8.053-1.971-15.576-0.238-17.189
                                                c-0.006-0.002,107.104-107.127,107.104-107.127h-58.24L35.92,323.723v58.238L156.1,261.783c4.686-4.686,12.283-4.686,16.971,0
                                                l137.146,137.148h58.238L202.429,232.906C202.43,232.904,202.431,232.902,202.432,232.902z" />
                            <path style={{fill: 'none'}} d="M296.389,155.434l60.26,60.26c4.688,4.688,4.688,12.285,0,16.971l-60.26,60.262l90.633,90.631V108.586
                                                h-43.783L296.389,155.434z" />
                            <path style={{fill: '#73D0F4'}} d="M152.584,66.244c0-23.293-18.949-42.244-42.242-42.244C87.049,24,68.1,42.951,68.1,66.244
                                                c0,15.094,22.445,47.521,42.242,70.992C130.139,113.766,152.584,81.338,152.584,66.244z" />
                            <rect x="242.809" y="187.569" transform="matrix(0.7071 0.7071 -0.7071 0.7071 240.3658 -131.9192)" style={{fill: '#73D0F4'}} width="73.22" height="73.221" />
                            <line style={{fill: 'none', stroke: '#3D6889', strokeWidth: 0, strokeLinecap: 'round', strokeLinejoin: 'round'}} x1="23.559" y1="422.932" x2="23.555" y2="422.932" />
                            <path style={{fill: '#3D6889'}} d="M399.022,84.586H172.899c2.461-6.824,3.686-12.924,3.686-18.342C176.584,29.717,146.867,0,110.342,0
                                                C73.815,0,44.1,29.717,44.1,66.244c0,5.418,1.225,11.518,3.686,18.342H23.92c-6.627,0-12,5.373-12,12v313.689
                                                c0,0-0.791,12.045,11.639,12.656c0.684,0,375.463,0,375.463,0c6.629,0,12-5.373,12-12V96.586
                                                C411.022,89.959,405.651,84.586,399.022,84.586z M110.342,24c23.293,0,42.242,18.951,42.242,42.244
                                                c0,15.094-22.445,47.521-42.242,70.992C90.545,113.766,68.1,81.338,68.1,66.244C68.1,42.951,87.049,24,110.342,24z M35.92,108.586
                                                h23.775c3.877,6.396,41.225,54.328,41.797,54.953c2.271,2.482,5.482,3.896,8.85,3.896c3.365,0,6.576-1.414,8.85-3.896
                                                c0.572-0.625,37.918-48.557,41.795-54.953h56.129L35.92,289.781V108.586z M52.891,398.932L164.584,287.24l111.691,111.691H52.891z
                                                M310.217,398.932L173.07,261.783c-4.688-4.686-12.285-4.686-16.971,0L35.92,381.961v-58.238l215.137-215.137h58.24
                                                c0,0-107.109,107.125-107.104,107.127c-1.732,1.613-7.195,9.137,0.238,17.189c-0.001,0-0.002,0.002-0.003,0.004l166.026,166.025
                                                H310.217z M279.42,172.404l51.773,51.775l-51.773,51.773l-51.775-51.773L279.42,172.404z M387.022,383.557l-90.633-90.631
                                                l60.26-60.262c4.688-4.686,4.688-12.283,0-16.971l-60.26-60.26l46.85-46.848h43.783V383.557z" />
                        </g>
                        </svg>
                          </div>
                          <div className="catagory-content">
                            <h3 className="elementor-inline-editing bdevs-el-title" data-elementor-setting-key="title">Land Publications</h3>
                            <span className="bdevs-el-desc">Land-is regarded as the greatest among the natural resources and is one of the most important components of life support systems. Land sustains many of the living beings...</span>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                  <div className='col-md-6 mb-4'>
                    <NavLink to="#">
                      <div className="catagory-wrapper">
                        <div className="catagory-thumb">
                        <svg width="55.594" height="56.553" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 504 504" xmlSpace="preserve">
                        <path style={{fill: '#EDC654'}} d="M150,130.2V109c0-30.8,25.2-55.2,56-55.2h104c30.8,0,52,24,52,55.2v18.4l-16,1.2v-20
                                            c0-22-14-38.8-36-38.8H206c-22,0-44,16.8-44,38.8v21.2h-12V130.2z" />
                        <path style={{fill: '#EF934A'}} d="M20.4,149.4l38.4,178c4.8,20.8,26,38.8,47.2,38.8h304c5.6,0,11.6-2.8,17.6-5.2c4.8-2,9.6-5.6,14-9.2
                                            c8-6.8,14-16.4,12-21.6l41.6-170c4-4.4-2.8-7.2-2.8-7.2c-8.8-11.6-18.8-22.8-34.4-22.8H57.6c-15.6,0-29.6,7.2-38.4,18.8
                                            C20,149,20,149,20.4,149.4z" />
                        <path style={{fill: '#E07D46'}} d="M495.2,152.2l-38,170.4c-2.4,10-8.4,20-17.2,27.2c-4.4,4-9.6,8.4-14.8,10.8
                                            c-6.4,2.8-12.8,5.6-18.8,5.6h-304c-22.8,0-40-16-45.2-38.4l-40-175.6c-4.4,7.2-14.8,15.6-14.8,24.8v220c0,26.4,25.2,49.2,51.6,49.2
                                            h400c26.4,0,48-22.4,48-49.2V177C502,168.2,499.6,159.4,495.2,152.2z" />
                        <path style={{fill: '#646464'}} d="M18,147.8c-1.2,0.4-1.6,1.2-1.6,2.4l0.4,2c0.8-1.2,1.6-2.8,2.8-4C19.2,147.8,18.4,147.8,18,147.8z" />
                        <g>
                            <path style={{fill: '#EDC654'}} d="M150,386.2c0,8.8-7.2,16-16,16h-8c-8.8,0-16-7.2-16-16v-40c0-8.8,7.2-16,16-16h8c8.8,0,16,7.2,16,16
                                                V386.2z" />
                            <path style={{fill: '#EDC654'}} d="M410,386.2c0,8.8-7.2,16-16,16h-8c-8.8,0-16-7.2-16-16v-40c0-8.8,7.2-16,16-16h8c8.8,0,16,7.2,16,16
                                                V386.2z" />
                            <ellipse style={{fill: '#EDC654'}} cx="257.6" cy="325.4" rx={40} ry={16} />
                        </g>
                        <path d="M452,452.2H52c-28.8,0-52-23.2-52-52v-220c0-28.8,23.2-52,52-52h400c28.8,0,52,23.2,52,52v220
                                            C504,429,480.8,452.2,452,452.2z M52,136.2c-24.4,0-44,19.6-44,44v220c0,24.4,19.6,44,44,44h400c24.4,0,44-19.6,44-44v-220
                                            c0-24.4-19.6-44-44-44H52z" />
                        <path d="M360,134.2c-2.4,0-4-1.6-4-4V109c0-29.2-18.4-49.2-46-49.2H202c-30.4,0-54,21.6-54,49.2v21.2c0,2.4-1.6,4-4,4s-4-1.6-4-4
                                            V109c0-32,27.2-57.2,62-57.2h108c32.4,0,54,22.8,54,57.2v21.2C364,132.6,362.4,134.2,360,134.2z" />
                        <path d="M344,134.2c-2.4,0-4-1.6-4-4V109c0-20.8-10.8-32.8-30-32.8H206c-20,0-42,13.6-42,32.8v21.2c0,2.4-1.6,4-4,4s-4-1.6-4-4V109
                                            c0-24.4,26-40.8,50-40.8h104c23.6,0,38,15.6,38,40.8v21.2C348,132.6,346.4,134.2,344,134.2z" />
                        <path d="M314,372.2H106c-23.2,0-47.6-20-53.2-43.6L12.4,153c-0.4-2,0.8-4.4,3.2-4.8c2-0.4,4.4,0.8,4.8,3.2L60.8,327
                                            c4.8,20.4,25.6,37.2,45.2,37.2h208c2.4,0,4,1.6,4,4S316.4,372.2,314,372.2z" />
                        <path d="M410,372.2h-56c-2.4,0-4-1.6-4-4s1.6-4,4-4h56c5.2,0,10.4-2,15.6-4.4l0.8-0.4c4-1.6,8-4.8,11.6-8l1.6-1.2
                                            c7.6-6.4,12.8-14.8,14.8-23.6l36.8-166.8c0.4-2,2.8-3.6,4.8-3.2s3.6,2.8,3.2,4.8l-36.8,166.8c-2.4,10.8-8.4,20.4-17.6,28l-1.6,1.2
                                            c-4,3.6-8.4,7.2-13.6,9.6l-0.8,0.4C422.8,369.4,416.8,372.2,410,372.2z" />
                        <path d="M132,408.2h-8c-11.2,0-20-8.8-20-20v-40c0-11.2,8.8-20,20-20h8c11.2,0,20,8.8,20,20v40C152,399.4,143.2,408.2,132,408.2z
                                            M124,336.2c-6.8,0-12,5.2-12,12v40c0,6.8,5.2,12,12,12h8c6.8,0,12-5.2,12-12v-40c0-6.8-5.2-12-12-12H124z" />
                        <path d="M392,408.2h-8c-11.2,0-20-8.8-20-20v-40c0-11.2,8.8-20,20-20h8c11.2,0,20,8.8,20,20v40C412,399.4,403.2,408.2,392,408.2z
                                            M384,336.2c-6.8,0-12,5.2-12,12v40c0,6.8,5.2,12,12,12h8c6.8,0,12-5.2,12-12v-40c0-6.8-5.2-12-12-12H384z" />
                        <path d="M257.6,345.4c-21.6,0-44-7.2-44-20s22.8-20,44-20s44,7.2,44,20S279.2,345.4,257.6,345.4z M257.6,313c-22,0-36,7.2-36,12
                                            s14,12,36,12s36-7.2,36-12S279.6,313,257.6,313z" />
                        </svg>
                          </div>
                          <div className="catagory-content">
                            <h3 className="elementor-inline-editing bdevs-el-title" data-elementor-setting-key="title">Corporate/Company/Organization</h3>
                            <span className="bdevs-el-desc">Corporate Vehicles including organizations are legal entities through which a wide variety of commercial and activities are conducted and assets are held...</span>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                  <div className='col-md-6'>
                    <NavLink to="#">
                      <div className="catagory-wrapper">
                        <div className="catagory-thumb">
                        <svg width="55.594" height="56.553" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xmlSpace="preserve">
                        <g transform="translate(1 1)">
                            <g>
                            <path style={{fill: '#FFDD09'}} d="M45.933,319c-2.56,0-4.267-0.853-5.973-2.56c-3.413-3.413-3.413-8.533,0-11.947l217.6-217.6
                                                c3.413-3.413,8.533-3.413,11.947,0s3.413,8.533,0,11.947L52.76,316.44C51.053,318.147,48.493,319,45.933,319z" />
                            <path style={{fill: '#FFDD09'}} d="M493.933,451.267c-5.12,0-8.533-3.413-8.533-8.533v-307.2c0-5.12,3.413-8.533,8.533-8.533
                                                c5.12,0,8.533,3.413,8.533,8.533v307.2C502.467,447.853,499.053,451.267,493.933,451.267z" />
                            <path style={{fill: '#FFDD09'}} d="M442.733,502.467h-0.853l-358.4-51.2c-4.267-0.853-7.68-5.12-6.827-9.387s5.12-7.68,9.387-6.827
                                                l358.4,51.2c4.267,0.853,7.68,5.12,6.827,9.387C450.413,499.053,447,502.467,442.733,502.467z" />
                            <path style={{fill: '#FFDD09'}} d="M502.467,442.733c0,33.28-26.453,59.733-59.733,59.733S383,476.013,383,442.733
                                                S409.453,383,442.733,383S502.467,409.453,502.467,442.733" />
                            </g>
                            <path style={{fill: '#FD9808'}} d="M442.733,383c-4.267,0-8.533,0.853-12.8,1.707c26.453,5.973,46.933,29.867,46.933,58.027
                                            c0,28.16-20.48,52.053-46.933,58.027c4.267,0.853,8.533,1.707,12.8,1.707c33.28,0,59.733-26.453,59.733-59.733
                                            S476.013,383,442.733,383" />
                            <path style={{fill: '#FFDD09'}} d="M92.867,280.6c-46.933,0-85.333,38.4-85.333,85.333s38.4,85.333,85.333,85.333
                                            s85.333-38.4,85.333-85.333S139.8,280.6,92.867,280.6" />
                            <path style={{fill: '#FD9808'}} d="M92.867,280.6c-4.267,0-8.533,0.853-12.8,0.853c40.96,5.973,72.533,41.813,72.533,84.48
                                            s-31.573,78.507-72.533,84.48c4.267,0,8.533,0.853,12.8,0.853c46.933,0,85.333-38.4,85.333-85.333S139.8,280.6,92.867,280.6" />
                            <path style={{fill: '#54C9FD'}} d="M92.867,400.067c-18.773,0-34.133-15.36-34.133-34.133S74.093,331.8,92.867,331.8
                                            S127,347.16,127,365.933S111.64,400.067,92.867,400.067" />
                            <path style={{fill: '#33A9F8'}} d="M92.867,331.8c-4.267,0-8.533,0.853-12.8,2.56c12.8,5.12,21.333,17.067,21.333,31.573
                                            s-8.533,26.453-21.333,31.573c4.267,1.707,8.533,2.56,12.8,2.56c18.773,0,34.133-15.36,34.133-34.133S111.64,331.8,92.867,331.8" />
                            <path style={{fill: '#FFDD09'}} d="M502.467,135.533c0,70.827-57.173,128-128,128s-128-57.173-128-128s57.173-128,128-128
                                            S502.467,64.707,502.467,135.533" />
                            <path style={{fill: '#FD9808'}} d="M374.467,7.533c-2.56,0-5.973,0-8.533,0.853C432.493,12.653,485.4,68.12,485.4,135.533
                                            s-52.907,122.88-119.467,127.147c2.56,0,5.973,0.853,8.533,0.853c70.827,0,128-57.173,128-128S445.293,7.533,374.467,7.533" />
                            <path style={{fill: '#54C9FD'}} d="M374.467,41.667c-52.053,0-93.867,41.813-93.867,93.867s41.813,93.867,93.867,93.867
                                            s93.867-41.813,93.867-93.867S426.52,41.667,374.467,41.667" />
                            <path style={{fill: '#33A9F8'}} d="M374.467,41.667c-2.56,0-5.973,0-8.533,0.853c47.787,4.267,85.333,44.373,85.333,93.013
                                            s-37.547,88.747-85.333,93.013c2.56,0,5.973,0.853,8.533,0.853c52.053,0,93.867-41.813,93.867-93.867S426.52,41.667,374.467,41.667
                                            " />
                            <path style={{fill: '#FFDD09'}} d="M374.467,161.133c-14.507,0-25.6-11.093-25.6-25.6s11.093-25.6,25.6-25.6
                                            c14.507,0,25.6,11.093,25.6,25.6S388.973,161.133,374.467,161.133" />
                            <path d="M374.467,272.067c-75.093,0-136.533-61.44-136.533-136.533S299.373-1,374.467-1S511,60.44,511,135.533
                                            S449.56,272.067,374.467,272.067z M374.467,16.067C308.76,16.067,255,69.827,255,135.533S308.76,255,374.467,255
                                            s119.467-53.76,119.467-119.467S440.173,16.067,374.467,16.067z" />
                            <path d="M442.733,511c-37.547,0-68.267-30.72-68.267-68.267s30.72-68.267,68.267-68.267S511,405.187,511,442.733
                                            S480.28,511,442.733,511z M442.733,391.533c-28.16,0-51.2,23.04-51.2,51.2c0,28.16,23.04,51.2,51.2,51.2
                                            c28.16,0,51.2-23.04,51.2-51.2C493.933,414.573,470.893,391.533,442.733,391.533z" />
                            <path d="M41.667,306.2c-2.56,0-4.267-0.853-5.973-2.56c-3.413-3.413-3.413-8.533,0-11.947l217.6-217.6
                                            c3.413-3.413,8.533-3.413,11.947,0c3.413,3.413,3.413,8.533,0,11.947l-217.6,217.6C45.933,305.347,44.227,306.2,41.667,306.2z" />
                            <path d="M502.467,451.267c-5.12,0-8.533-3.413-8.533-8.533v-307.2c0-5.12,3.413-8.533,8.533-8.533c5.12,0,8.533,3.413,8.533,8.533
                                            v307.2C511,447.853,507.587,451.267,502.467,451.267z" />
                            <path d="M442.733,511h-0.853L92.013,459.8c-4.267-0.853-7.68-5.12-6.827-9.387s5.12-7.68,9.387-6.827l349.867,51.2
                                            c4.267,0.853,7.68,5.12,6.827,9.387C450.413,507.587,447,511,442.733,511z" />
                            <path d="M442.733,451.267c-5.12,0-8.533-3.413-8.533-8.533c0-5.12,3.413-8.533,8.533-8.533c5.12,0,8.533,3.413,8.533,8.533
                                            C451.267,447.853,447.853,451.267,442.733,451.267" />
                            <path d="M374.467,84.333c-5.12,0-8.533-3.413-8.533-8.533s3.413-8.533,8.533-8.533c5.12,0,8.533,3.413,8.533,8.533
                                            C383,80.92,379.587,84.333,374.467,84.333" />
                            <path d="M374.467,203.8c-5.12,0-8.533-3.413-8.533-8.533c0-5.12,3.413-8.533,8.533-8.533c5.12,0,8.533,3.413,8.533,8.533
                                            C383,200.387,379.587,203.8,374.467,203.8" />
                            <path d="M92.867,459.8C40.813,459.8-1,417.987-1,365.933s41.813-93.867,93.867-93.867s93.867,41.813,93.867,93.867
                                            S144.92,459.8,92.867,459.8z M92.867,289.133c-42.667,0-76.8,34.133-76.8,76.8s34.133,76.8,76.8,76.8s76.8-34.133,76.8-76.8
                                            S135.533,289.133,92.867,289.133z" />
                            <path d="M425.667,135.533c0-5.12,3.413-8.533,8.533-8.533c5.12,0,8.533,3.413,8.533,8.533s-3.413,8.533-8.533,8.533
                                            C429.08,144.067,425.667,140.653,425.667,135.533" />
                            <path d="M306.2,135.533c0-5.12,3.413-8.533,8.533-8.533c5.12,0,8.533,3.413,8.533,8.533s-3.413,8.533-8.533,8.533
                                            C309.613,144.067,306.2,140.653,306.2,135.533" />
                            <path d="M340.333,92.867c0,5.12-3.413,8.533-8.533,8.533s-8.533-3.413-8.533-8.533s3.413-8.533,8.533-8.533
                                            S340.333,87.747,340.333,92.867" />
                            <path d="M425.667,178.2c0,5.12-3.413,8.533-8.533,8.533s-8.533-3.413-8.533-8.533c0-5.12,3.413-8.533,8.533-8.533
                                            S425.667,173.08,425.667,178.2" />
                            <path d="M408.6,92.867c0-5.12,3.413-8.533,8.533-8.533s8.533,3.413,8.533,8.533s-3.413,8.533-8.533,8.533
                                            S408.6,97.987,408.6,92.867" />
                            <path d="M331.8,186.733c-5.12,0-8.533-3.413-8.533-8.533c0-5.12,3.413-8.533,8.533-8.533s8.533,3.413,8.533,8.533
                                            C340.333,183.32,336.92,186.733,331.8,186.733" />
                            <path d="M374.467,237.933c-56.32,0-102.4-46.08-102.4-102.4s46.08-102.4,102.4-102.4s102.4,46.08,102.4,102.4
                                            S430.787,237.933,374.467,237.933z M374.467,50.2c-46.933,0-85.333,38.4-85.333,85.333s38.4,85.333,85.333,85.333
                                            s85.333-38.4,85.333-85.333S421.4,50.2,374.467,50.2z" />
                            <path d="M374.467,169.667c-18.773,0-34.133-15.36-34.133-34.133s15.36-34.133,34.133-34.133c18.773,0,34.133,15.36,34.133,34.133
                                            S393.24,169.667,374.467,169.667z M374.467,118.467c-9.387,0-17.067,7.68-17.067,17.067s7.68,17.067,17.067,17.067
                                            s17.067-7.68,17.067-17.067S383.853,118.467,374.467,118.467z" />
                            <path d="M92.867,408.6c-23.893,0-42.667-18.773-42.667-42.667c0-23.893,18.773-42.667,42.667-42.667s42.667,18.773,42.667,42.667
                                            C135.533,389.827,116.76,408.6,92.867,408.6z M92.867,340.333c-14.507,0-25.6,11.093-25.6,25.6s11.093,25.6,25.6,25.6
                                            s25.6-11.093,25.6-25.6S107.373,340.333,92.867,340.333z" />
                        </g>
                        </svg>
                          </div>
                          <div className="catagory-content">
                            <h3 className="elementor-inline-editing bdevs-el-title" data-elementor-setting-key="title">Motor Vehicles Publications</h3>
                            <span className="bdevs-el-desc">Private cars are said to have transformed modern society by providing independence and freedom of mobility...</span>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                </div>

              </div>
          </div>
      </div>
    </>
  );
}

export default Diligence;