import React from "react";

import { Link, NavLink } from "react-router-dom";
import HomeGallery from "./HomeGallery";

function HomeHero() {
  return (
    <>
      {/* End Hero */}
      <div className="campus-area grey-2 fix pt-120 pb-70">
        <div className="container">
          <div className="campus-wrapper position-relative">
            {/* <div className="campus-shape-1">
              <img src="/img/shape/campus-shape-2.png" alt="shape" />
            </div>
            <div className="campus-shape-2">
              <img src="/img/shape/student-shape-05.png" alt="shape" />
            </div> */}
            <div className="row align-items-center">
              <div className="col-xl-5 col-lg-6">
                <div className="hero-text">
                  <h5>WELCOME TO DATA G</h5>
                  <h2>
                    We remember,
                    <br />
                    So you don't forget
                  </h2>
                  <p>
                    <b>Data G</b> is a Data Warehouse that processes
                    select published information derived from diverse sources and
                    avails it for use by those seeking information to make
                    decisions.
                  </p>
                  <div className="hero-btn">
                    <NavLink
                      className="edu-btn"
                      to="http://portal.datag.co.ke/Registration/Register"
                    >
                      Get Started
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 offset-xl-1 col-lg-6">
                <HomeGallery />
                <p>
                  -------Bridging the gap between <strong>certainty</strong> and
                  <b> uncertainty</b>
                  ------
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeHero;
