import React,{ useEffect } from 'react';
import { Element, scroller } from 'react-scroll';

function Industries() {
    useEffect(() => {
        const hash = window.location.hash.substr(1);
        if (hash) {
          scroller.scrollTo(hash, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
          });
        }
    }, []);

  return (
    <>
        <div>
            <div className="become-intructor-area pt-110 pb-120">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-xl-6 col-md-8">
                            <div className="pt-50">
                                <span className='mb-3'>Work With Us</span>
                                <h2>Industries We Serve</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Element name="financial" className="teacher-area position-relative pt-120 pb-100 fix">
                <div className="container">
                    <div className="row align-items-center justify-content-center gap-2">
                        <div className="col-xxl-4 col-xl-5 col-lg-5">
                            <div className="teacher-img position-relative">
                                <img src="/img/teacher/teacher.png" alt="image not found" className="teacher-main-img" />
                                {/* <img src="/img/teacher/teacher-shape-01.png" alt="image not found" className="teacher-shape" /> */}
                                <img src="/img/teacher/teacher-shape-02.png" alt="image not found" className="teacher-shape-02" />
                                <img src="/img/teacher/teacher-shape-03.png" alt="image not found" className="teacher-shape-03" />
                                {/* 
                                <img src="/img/teacher/teacher-shape-04.png" alt="image not found" className="teacher-shape-04" /> */}
                            </div>
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-lg-5">
                            <div className="teacher-content mr-20">
                                <div className="section-title mb-30">
                                    <h2>Financial Institutions</h2>
                                </div>
                                <p>Financial institutions world over are regulated generally as a response to the need for systems to maximize the mobilization and intermediation of funds, enhance efficiency in the allocation of capital, ensure appropriate risk management, and protect depositors. </p>
                                <p>Achieving regulatory compliance is not a walk in the park. Consequently, our KYC and CDD Solutions are designed to help you with all your needs to reach your compliance objectives.</p>
                                <p>We help you discover hidden risks and gain insights that reduce operational risks  to the company in order to guarantee in return financial health and reputation.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Element>

            <Element name="micro" className="bg-custom teacher-area position-relative pt-120 pb-100 fix">
                <div className="container">
                    <div className="row align-items-center justify-content-center gap-2">
                        <div className="col-xxl-5 col-xl-5 col-lg-5">
                            <div className="teacher-content mr-20">
                                <div className="section-title mb-30">
                                    <h2>Micro Finance Institutions </h2>
                                </div>
                                <p>Recent developments concerning financial inclusion have led to regulation of NBFIs and Thrift Institutions in financial services regulation hence subjecting them to the onerous regulatory compliance requirements.</p>
                                <p>Our KYC and CDD Solutions can come in handy for NBFIs and financial thrift institutions. Our Solutions are very cost effective. </p>
                            </div>
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-lg-5">
                            <div className="teacher-img position-relative">
                                <img src="/img/micro-finance.png" alt="image not found" className="teacher-main-img" />
                                {/* <img src="/img/teacher/teacher-shape-01.png" alt="image not found" className="teacher-shape" /> */}
                                <img src="/img/micro-finance-1.png" alt="image not found" className="teacher-shape-02" />
                                {/* <img src="/img/teacher/teacher-shape-03.png" alt="image not found" className="teacher-shape-03" /> */}
                                {/* 
                                <img src="/img/teacher/teacher-shape-04.png" alt="image not found" className="teacher-shape-04" /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Element>
            
            
            <Element name="sme" className="teacher-area position-relative pt-120 pb-100 fix">
                <div className="container">
                    <div className="row align-items-center justify-content-center gap-2">
                        <div className="col-xxl-4 col-xl-5 col-lg-5">
                            <div className="teacher-img position-relative">
                                <img src="/img/teacher/teacher.png" alt="image not found" className="teacher-main-img" />
                                {/* <img src="/img/teacher/teacher-shape-01.png" alt="image not found" className="teacher-shape" /> */}
                                <img src="/img/teacher/teacher-shape-02.png" alt="image not found" className="teacher-shape-02" />
                                <img src="/img/teacher/teacher-shape-03.png" alt="image not found" className="teacher-shape-03" />
                                {/* 
                                <img src="/img/teacher/teacher-shape-04.png" alt="image not found" className="teacher-shape-04" /> */}
                            </div>
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-lg-5">
                            <div className="teacher-content mr-20">
                                <div className="section-title mb-30">
                                    <h2>Micro-, small- and medium-sized enterprises (MSMEs)</h2>
                                </div>
                                <p>Micro-, small- and medium-sized enterprises (MSMEs) have been noted as important contributors to the economic growth of many countries.  </p>
                                <p>Risk understanding, profiling and mitigation is germane to survival and prosperity of SMES.</p>
                                <p>DATA G can prove critically useful to SMES as it contribute towards de-risking critical decisions by creating complete Information that removes significant uncertainty in decision-making processes and allowing use of the processed data generated from multiple sources to provide strategic insights.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Element>

        </div>
    </>
  );
}

export default Industries;