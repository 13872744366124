import React,{} from 'react';
import { NavLink } from 'react-router-dom';

function Footer() {
  return (
    <>
        <div>
            {/* ======= Footer ======= */}
            {/* <footer id="footer">
                <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-lg-6 text-lg-left text-center">
                    <div className="copyright">
                        © Copyright <strong>Vesperr</strong>. All Rights Reserved
                    </div>
                    <div className="credits">
                        Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
                    </div>
                    </div>
                    <div className="col-lg-6">
                    <nav className="footer-links text-lg-right text-center pt-2 pt-lg-0">
                        <a href="#intro" className="scrollto">Home</a>
                        <a href="#about" className="scrollto">About</a>
                        <a href="#">Privacy Policy</a>
                        <a href="#">Terms of Use</a>
                    </nav>
                    </div>
                </div>
                </div>
            </footer>
            <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short" /></a> */}
            
            <div className="progress-wrap">
                <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
                </svg>
            </div>

            <footer>
                <div className="footer-area pt-100">
                    <div className="container">
                    <div className="footer-main mb-60">
                        <div className="row">
                        <div className="col-md-4">
                            {/* <img className="mb-40" src="assets/img/dg_logo_white_black.png" height={90} /> */}
                            <p className="m-2 text-muted d-flex gap-2">
                                <strong>Phone:</strong> +254 799 298 611 | +254 703 176 392
                            </p>
                            <p className="m-2 text-muted d-flex gap-2">
                                <strong>Email:</strong> support@dataghala.com | sales@dataghala.com
                            </p>
                            <p className="m-2 text-muted d-flex gap-2">
                            <strong>Address:</strong> P.O BOX 60038-00200 Nairobi – Kenya
                            </p>
                            <p className="m-2 text-muted d-flex gap-2">
                                <strong>Office:</strong> DATA GHALA LTD Kogo Star Plaza, Office No. 16 Ground Floor, Nairobi West
                            </p>
                        </div>
                        <div className="col-md-8">
                            <div className="footer-widget f-w1 mb-40">
                            <div className="footer-img">
                                <h3>Disclaimer</h3>
                                <p className="m-0 text-muted">Data Ghala Ltd has used all reasonable endeavours to ensure that information available in its database is accurate and up to date.However,the information is provided in good faith,on a best endeavours basis without any warranty as to its completeness or accuracy.Formal confirmation of details should be obtained from or with the consent of the individual,natural or otherwise, involved. Data Ghala Ltd does not accept any responsibility for any reliance placed on the information contained in its database.</p>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-5">
                        <div className="sub-footer-text pb-3">
                            <span>© Copyrigh 2024 | All rights reserved
                            </span>
                        </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-7">
                        <div className="sub-footer-link pb-3">
                            <ul>
                            <li><NavLink>Privacy Policy</NavLink></li>
                            <li><NavLink>Terms &amp; Condition</NavLink></li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </footer>
        </div>
    </>
  );
}

export default Footer;