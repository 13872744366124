import React,{ } from 'react';
import { NavLink } from 'react-router-dom';

function Individual() {
  return (
    <>
        <div>
          <div className="bg-light campus-area grey-2 fix pt-120 pb-70 ">
            <div className="container">
              <div className="campus-wrapper position-relative">
                {/* <div className="campus-shape-sticker">
                    <div className="shape-light">
                        <img src="~/wwwroot/theme/img/shape/shape-light.png" alt="image not found">
                    </div>
                    <div className="campus-shape-content">
                        <h5>Education is the<span> Platform
                                that makes</span>possible to everyone</h5>
                    </div>
                </div> */}
                <div className="row align-items-center py-5">
                  <div className="col-xl-5 col-lg-6">
                    <div className="hero-text">
                      <h5>Due Diligence Datasets</h5>
                      <h2>Individual Persons Publications Data Sets </h2>
                      <p>Persons (both natural and juridical); business and other entities (including statutory bodies), the legal instruments that facilitate relations between persons and entities and the incidental administrative and legal procedures form the basis of every single level of interactions in every sphere of a society.  Accordingly, a person in a triangular sense as a legal subject, an economic element and a legal element is of interest for purposes of due diligence. </p>
                      <div className="hero-btn">
                        <NavLink className="edu-btn" to="https://datag.co.ke/staging/Registration/Register">Get Started</NavLink>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 offset-xl-1 col-lg-6">
                    <div className="student-choose-wrapper position-relative mb-30">
                        <div className="shap-02">
                        </div>
                        <div className="shap-03">
                            <img src="/img/shape/student-shape-03.png" alt="image not found" />
                        </div>
                        <div className="shap-04">
                            <img src="/img/shape/student-shape-04.png" alt="image not found" />
                        </div>
                        <div className="shap-05">
                            <img src="/img/shape/student-shape-05.png" alt="image not found" />
                        </div>
                        <div className="shap-06">
                            <img src="/img/shape/student-shape-06.png" alt="image not found" />
                        </div>
                        <div className="shap-07">
                            <img src="/img/shape/student-shape-07.png" alt="image not found" />
                        </div>
                        <div className="student-choose-thumb">
                            <img src="/img/student-choose/student.png" alt="image not found" />
                        </div>
                        </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="about-area p-relative pt-90 pb-70">
            <div className="container">
              {/* <img className="about-shape" src="assets/img/shape/education-shape-03.png" alt="shape" /> */}
              <div className="row">
                <div className="col-xl-6 col-lg-6">
                  <div className="about-img position-relative mb-50">
                    <div className="about-main-img">
                      <img src="img/data-aggrigation.gif" alt="about" />
                    </div>
                    {/* <img className="about-shape-1" src="/img/shape/education-shape-01.png" alt="about" />
                    <img className="about-shape-2" src="assets/img/shape/education-shape-02.png" alt="about" />
                    <img className="about-shape-3" src="assets/img/shape/education-shape-05.png" alt="about" /> */}
                  </div>
                </div>
                <div className="col-xl-5 col-lg-5">
                  <div className="about-content mb-50">
                    <div className="student-choose-list">
                        <p><b>Individual Persons Publications Data Sets serves a wealth of country specific data derived from thousands of content sources, allowing you to conduct comprehensive checks on Individual Persons.  </b></p>
                      <p className=" mb-1">Many transactions be they business or otherwise involving individuals require legal personhood to fulfil certain rights and activities. In addition, an array of legal instruments are used to validate, clarify and enforce transactions. For example, one cannot be a medical practitioner, a legal practitioner, a financial practitioner or undertake any other regulated trade unless such a person meets the requirements set out in laws governing such trades which may include academic qualifications and other prerequisites. Contracts constitute most of the legal instruments that are used to validate, clarify and enforce transactions. Accordingly, risk mitigation practices before, during and in some cases after entering into a contractual arrangement prescribes due diligence. Accordingly, employee background checks, academic qualifications verification, verification of official appointments etc are now part of necessary due diligence processes for decision making. 
</p>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="become-intructor-area pt-110 pb-120">
            <div className="container">
                <div className="row justify-content-center text-center">
                  <div className="col-12">
                      <div className="become-intructor-text">
                      <h2>What You Get</h2>
                      <p>The list of individual publication data sets include the following</p>
                      <div className='row'>
                        <div className='col-md-6'>
                            <div className="student-choose-list">
                                <ul>
                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Passing of Professional and other examinations/certification and registration by and with professional bodies</li>
                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Probate and Administration- Notice by an Individual</li>
                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Probate and Administration- Notice by a Public Trustee</li>
                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Revocation of official appointment and/or nomination to office</li>
                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Notification of Loss of Insurance Policy</li>
                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Publication of Power of Attorney</li>
                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Bankruptcy Notice </li>
                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Notification of official Appointment/Nomination to office</li>
                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Revocation of official appointment and/or nomination to office </li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="student-choose-list">
                                <ul>
                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Listing for Public Service Recruitment</li>
                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Substituted service of court processes</li>
                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Public Notification by Law Enforcement Agency</li>
                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Public Disclaimer by Employer/Organization</li>
                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Notice of Missing Person</li>
                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Fundraising Appeal to Well Wishers</li>
                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Notification of Death and Funeral Arrangements</li>
                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Listing in Sanctions Watchlists</li>
                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Notice relating to Unclaimed Financial Assets</li>
                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Information derived from Sanctions, Watchlists and Politically Exposed Persons.</li>
                                </ul>
                            </div>
                        </div>
                      </div>
                      </div>
                  </div>
                  </div>
              </div>
          </div>
      </div>
    </>
  );
}

export default Individual;