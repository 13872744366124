import React,{ useEffect } from 'react';
import { Element, scroller } from 'react-scroll';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Solutions() {
    const CustomPrevArrow = (props) => {
        const { className, onClick } = props;
        return (
          <div className={className} onClick={onClick}>
            {/* Your custom previous arrow */}
            <i className="bi bi-chevron-left"></i>
          </div>
        );
    };
      
    const CustomNextArrow = (props) => {
        const { className, onClick } = props;
        return (
          <div className={className} onClick={onClick}>
            {/* Your custom next arrow */}
            <i className="bi bi-chevron-right"></i>
          </div>
        );
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true, // Enable auto-scrolling
        autoplaySpeed: 10000, // Set the duration between slides
        arrows: true, // Enable navigation arrows
        prevArrow: <CustomPrevArrow />, // Custom previous arrow component
        nextArrow: <CustomNextArrow /> // Custom next arrow component
    };

    useEffect(() => {
        const hash = window.location.hash.substr(1);
        if (hash) {
          scroller.scrollTo(hash, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
          });
        }
    }, []);

  return (
    <>
        <div>
            <div className="become-intructor-area pt-110 pb-120">
                <div className="container">
                    <div className="row justify-content-center text-center mb-80">
                        <div className="col-xl-6 col-md-8">
                            <div className="pt-50 mb-100">
                                <span className='mb-3'>Our Solutions</span>
                                <h2>What We Offer</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="features-video-area mb-100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div>
                            <div className="features-video-wrapper">
                            {/* <div className='d-flex justify-content-between'>
                                <CustomPrevArrow/>
                                <CustomNextArrow/>
                            </div> */}
                            <Slider {...settings}>
                                <div className='p-5'>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="education-img mb-30">
                                                <img decoding="async" src="/media/business_risk.gif" alt="image not found" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="section-title">
                                                <h2 className="bd-section-title mb-25 md-pr-50 bdevs-el-title">Risk Intelligence</h2>
                                            </div>
                                            <div className="education-content bdevs-el-content">
                                                <p>DATA G aims to contribute towards de-risking critical decisions by creating complete Information that removes significant uncertainty in decision-making processes and allowing use of the processed data generated from multiple sources to provide strategic insights.</p>
                                                <p>While embracing change and innovation, we endeavor to deliver intelligent solutions to serve universal information needs in relevant context specific format.</p>
                                                <p>We are major contributors to the creation of transparent environments for business and non-business transactions. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='p-5'>
                                    <div className="row">
                                        <div className="col-md-8">
                                            <div className="section-title">
                                                <h2 className="bd-section-title md-pr-50 bdevs-el-title">Know Your Customer (KYC) and Customer Due Diligence (CDD) solutions</h2>
                                            </div>
                                            <div className="education-content bdevs-el-content">
                                                <p>Our Know Your Customer (KYC) and Customer Due Diligence (CDD) solutions is a cocktail of intuitive technology such as, machine learning and AI, analytics and expansive global risk intelligence to support a comprehensive Know Your Customer (KYC) workflow across the entire customer lifecycle.</p>
                                                <p>With our integrated tools, you are set for an effective customer lifecycle management strategy consistent with the current financial crime compliance risk realities and compliments core operations for global financial institutions: </p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="education-img">
                                                <img decoding="async" src="/img/kyc-details.gif" alt="image not found" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className="student-choose-list">
                                                <ul>
                                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Customer Due Diligence (CDD)</li>
                                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Enhanced Due Diligence</li>
                                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Continuing Due Diligence</li>
                                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Risk Profiling</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="student-choose-list">
                                                <ul>
                                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Watchlist Screening</li>
                                                    <li className="bdevs-el-list d-flex gap-2"><i className="fas fa-check-circle"></i>Adverse Media, Politically Exposed Person (PEP), Sanctions and Enforcements and more.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='p-5'>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="education-img mb-30">
                                                <img decoding="async" src="/media/media_intelligence.gif" alt="image not found" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="section-title mt-3">
                                                <h2 className="bd-section-title mb-25 md-pr-50 bdevs-el-title">Media Intelligence </h2>
                                            </div>
                                            <div className="education-content bdevs-el-content">
                                                <p>We track statute or rule mandated publications about individuals, corporations and assets and present them in format designed to enrich your decision-making thought process.</p>
                                                <p>We mmonitor mainstream and social media and come up with comprehensive, tailor-made market briefs and reports that can be delivered on a daily, weekly, monthly, quarterly, or even yearly basis.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </div>
    </>
  );
}

export default Solutions;