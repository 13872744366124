import React,{} from 'react';

import { Link, NavLink } from "react-router-dom";

function FeatureVideo() {
  return (
    <>
        <div className="features-video-area">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10 col-md-9">
                        {/* <div>
                            <div className="features-video-wrapper">
                                {/* <div className="features-shape-wrapper-1">
                                            <img className="features-shape" src="assets/img/shape/features-shape.png" alt="features-shape">
                                            Get <span>help</span> from <br> this video
                    
                                        </div>
                                        <div className="features-shape-werapper-2">
                                            <span>65k+</span> <br>views daily
                                            <img className="features-shape-2" src="assets/img/shape/features-shape-2.png" alt="features-shape-2">
                                        </div> */}
                                {/* <div className="reatures-video-thumb">
                                    <img src="../img/video.jpg" alt="features-img" />
                                </div> */}
                                {/* <div className="features-video-content">
                                    <div className="features-btn">
                                        <NavLink className="popup-video" to={"https://www.youtube.com/watch?v=C3E9rmdLOK0"}>
                                            <i className="fas fa-play" />
                                        </NavLink>
                                    </div>
                                    <div className="video-btn-text">
                                        <span>Watch Video
                                        <br />Intro</span>
                                    </div>
                                </div> */}
                            {/* </div> */}
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default FeatureVideo;